import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class ReceivablesReportService {
    readReceivablesReportQuery(){
        var query = `
            query ($startDate: DateTime!, $endDate: DateTime!, $id: Int, $status: String, $cutOff: DateTime) {
                GetReportingReceivables(start_date:$startDate, end_date:$endDate, customer_id:$id, status:$status, cut_off:$cutOff) {
                    invoice_id
                    customer_name
                    customer_id
                    due_date
                    invoice_date
                    due_day
                    invoice_number
                    total
                    paid
                    outstanding_amount
                    status
                    last_payment
                } 
            }`;
        return query;
    }
    
    async getReceivablesReportQuery(variables){
        var query = `
            query ($startDate: DateTime!, $endDate: DateTime!, $id: Int, $status: String, $cutOff: DateTime) {
                GetReportingReceivables(start_date:$startDate, end_date:$endDate, customer_id:$id, status:$status, cut_off:$cutOff) {
                    invoice_id
                    customer_name
                    customer_id
                    due_date
                    invoice_date
                    due_day
                    invoice_number
                    total
                    paid
                    outstanding_amount
                    status
                    last_payment
                } 
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetReportingReceivables;
    }

    async getCustomerQuery(){
        var query = gql`
            query {
                GetContact (ContactType:"Distributor") {
                    contact_id
                    contact_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { 
                    value: result.data.GetContact[i].contact_id, 
                    label: result.data.GetContact[i].contact_name
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    receivableReportExcelHeaderSize(){
        var size = [
            {width: 40}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 40}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
        ];

        return size;
    }

    receivableReportExcel(data, variables){
        const uniques = [];
        data.forEach((t) => !uniques.includes(t.customer_id) && uniques.push(t.customer_id));

        var arrayObject = [];
        var date = 'Per ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS'};
        arrayObject.push(row1);

        var row2 = {colA : 'PIUTANG DAGANG'};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            for (let k= 0; k < uniques.length; k++) {                
                var row5 = {
                    colA : 'Customer',
                    colB : 'Tgl Jatuh Tempo',
                    colC : 'Due Day',
                    colD : 'No Invoice',
                    colE : 'Nilai Invoice',
                    colF : 'Outstanding'
                };
                arrayObject.push(row5);

                var detail = data.filter(c => c.customer_id == uniques[k])
                var total_outstanding = 0;
                for (let i = 0; i < detail.length; i++){
                    var row = {
                        colA : detail[i].customer_name,
                        colB : detail[i].due_date != '' ? { v: detail[i].due_date, t: 'd', z: 'DD-MMM-YYYY' } : '-',
                        colC : { v: detail[i].due_day, t: 'n' , z: numberFormat},
                        colD : detail[i].invoice_number,
                        colE : { v: detail[i].total, t: 'n' , z: numberFormat},
                        colE : { v: detail[i].outstanding_amount, t: 'n' , z: numberFormat},
                    };
                    arrayObject.push(row);

                    total_outstanding += detail[i].outstanding_amount;
                }

                var customerData = data.find(c => c.customer_id == uniques[k])
                var row = { 
                    colA : 'Total Per ' + customerData.customer_name,
                    colB : '',
                    colC : '',
                    colD : '',
                    colE: '',
                    colF : {v: total_outstanding , t: 'n', z: numberFormat},
                };
                arrayObject.push(row);
                        
                var spaceRow = {colA : null};
                arrayObject.push(spaceRow);
            }
        }

        return arrayObject;
    }
}


export default new ReceivablesReportService();